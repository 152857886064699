<template>
    <div class="star-order-container">
        <le-card width="100%" height="600px">
            <div class="card-title">用户下单</div>

            <div class="clear-box">
                <div class="label">自动清空时间：</div>
                <div class="val">
                    {{info.clear_time}}
                </div>

                <div class="label label-right">自动发放时间：</div>
                <div class="val">
                    {{info.send_time}}
                </div>
            </div>

            <div class="list">
                <div class="list-left">推广房间：</div>
                <div class="list-right">
                    <a-input v-model="room_id" class="input-room"></a-input>
                </div>
            </div>
            <div class="list">
                <div class="list-left">下单星光：</div>
                <div class="list-right">
                    <a-input-number
                            :min="1"
                            v-model="starlight"
                            class="input-starlight"
                    ></a-input-number>
                    剩余星光:
                    {{ info.starlight }}
                </div>
            </div>
            <div class="list">
                <div class="list-left">预计新增曝光：</div>
                <div class="list-right">
                    <div class="value">{{ (starlight * 100) | money }}</div>
                </div>
            </div>
            <div class="list" style="margin-top: 10px">
                <div class="list-left"></div>
                <div class="list-right">
                    <div class="tips">
                        此曝光量仅为预估值，最终曝光量以实际为准，实际效果与直播质量有关。
                    </div>
                </div>
            </div>
            <div class="list">
                <div class="list-left">推广时间：</div>
                <div class="list-right">
                    <a-date-picker show-time v-model="start_time" class="input-date"></a-date-picker>
                    <le-button
                            txt="自动开始"
                            width="100px"
                            height="35px"
                            @btnClick="autoSend"
                            style="margin-left: 10px;"
                    ></le-button>
                </div>
            </div>
            <div class="list">
                <div class="list-left">支付：</div>
                <div class="list-right">
                    <div class="value-star">{{ starlight }}</div>
                    <div class="txt-star">星光</div>
                </div>
            </div>
            <div class="list" v-if="isBtn">
                <div class="list-left"></div>
                <div class="list-right">
                    <le-button
                            txt="立即下单"
                            width="100px"
                            height="35px"
                            :loading="isLoad"
                            @btnClick="createUnionOrder"
                    ></le-button>
                </div>
            </div>
        </le-card>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "star-order",
        components: {},
        data() {
            return {
                room_id: "",
                starlight: 0,
                start_time: null,
                info: {},
                clearObj: {},
                sendObj: {},
                isBtn: false,
                isLoad: false
            };
        },
        computed: {},
        activated() {
            console.log('activated star-order');
            this.getBalance()
        },
        created() {
            let btn = window.localStorage.getItem('whiteBtns')
            if (btn) {
                let btns = btn.split(',');
                if (btns.includes('star-order-setOrder')) {
                    this.isBtn = true;
                }
            }

            // this.whiteBtns= this.$store.state.buttons
            console.log('buttons', this.whiteBtns, this.$store.state.buttons)
            this.getBalance()
        },
        mounted() {
            // this.getBalance()
            // this.getInfo();
            // this.get_clear_time(); //获取自动清空时间
            // this.get_send_time(); //获取自动发放的时间
        },
        filters: {
            week(val) {
                if (!val) {
                    return "--";
                }
                switch (val) {
                    case 1:
                        return "一";
                        break;
                    case 2:
                        return "二";
                        break;
                    case 3:
                        return "三";
                        break;
                    case 4:
                        return "四";
                        break;
                    case 5:
                        return "五";
                        break;
                    case 6:
                        return "六";
                        break;
                    case 7:
                        return "日";
                        break;
                }
            },
        },
        methods: {
            getBalance() {
                this.$api
                    .post(`v2/admin/getBalance`)
                    .then((res) => {
                        if (res.code == 200) {
                            console.log(res);
                            this.info = res.data;
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            },

            getInfo() {
                this.$api.get("v1/user/info").then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.info = res.data;
                    }
                });
            },
            autoSend() {
                this.start_time = null;
            },
            createUnionOrder() {
                this.isLoad = true;
                let param = {
                    room_id: this.room_id,
                    starlight: this.starlight,
                };
                if (this.start_time) {
                    param.start_time = moment(this.start_time).format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                }

                this.$api
                    .post(`v1/user/starlight/createUnionOrder`, param)
                    .then((res) => {
                        this.isLoad = false
                        if (res.code == 200) {
                            this.$message.success("发放成功");
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            },
            get_clear_time() {
                this.$api.get(`v1/user/starlight/get_clear_time`).then((res) => {
                    if (res.code == 200) {
                        this.clearObj = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            get_send_time() {
                this.$api.get(`v1/user/starlight/get_send_time`).then((res) => {
                    if (res.code == 200) {
                        this.sendObj = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .star-order-container {
        width: 100%;

        .clear-box {
            display: flex;
            align-items: center;
            margin-left: 30px;
            margin-top: 10px;

            .label {
                font-size: 16px;
                color: #333333;
            }

            .val {
                font-size: 14px;
                color: #008df0;
            }
        }

        .list {
            display: flex;
            margin-top: 20px;
            align-items: center;

            .list-left {
                width: 200px;
                text-align: right;
                font-size: 16px;
                color: #333333;
            }

            .list-right {
                display: flex;
                align-items: center;
                flex: 1;

                .input-room {
                    width: 212px;
                }

                .input-starlight {
                    margin-right: 20px;
                    width: 212px
                }

                .input-date {
                    width: 212px;
                }

                .value {
                    font-size: 36px;
                    font-weight: bold;
                    color: #008df0;
                }

                .tips {
                    font-size: 16px;
                    color: #cccccc;
                }

                .value-star {
                    font-size: 36px;
                    font-weight: bold;
                    color: #ff6363;
                }

                .txt-star {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 10px;
                    margin-top: 5px;
                }
            }
        }
    }

    @media screen and(max-width: 750px) {
        .star-order-container {
            .clear-box {
                margin-left: 20px;
                margin-top: 10px;
                flex-wrap: wrap;

                .label-right {
                    margin-left: 0px;
                }

                .val {
                    width: 200px;
                }
            }

            .list {
                margin-top: 10px;
                align-items: flex-start;

                .list-left {
                    width: 120px;
                    font-weight: 400;
                }

                .list-right {
                    flex-wrap: wrap;

                    .input-room {
                        width: 150px;
                    }

                    .input-starlight {
                        margin-right: 10px;
                        width: 150px
                    }

                    .input-date {
                        width: 150px;
                    }
                }
            }
        }
    }

</style>
